define("ui/components/modal-view-template-diff/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "28Imj2a8",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"horizontal-form container-fluid\"],[12],[2,\"\\n  \"],[10,\"h2\"],[12],[1,[30,[36,0],[\"clusterTemplatesPage.compare.header\"],null]],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"mt-10 modal-scroll-panel\"],[12],[2,\"\\n    \"],[10,\"pre\"],[14,0,\"bg-setting\"],[14,5,\"margin: 0; padding: 0\"],[12],[2,\"      \"],[1,[34,1]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"footer-actions\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"mt-10 btn bg-primary\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"cancel\"],null],[12],[1,[30,[36,0],[\"clusterTemplatesPage.compare.closeModal\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"diff\",\"action\"]}",
    "moduleName": "ui/components/modal-view-template-diff/template.hbs"
  });

  _exports.default = _default;
});