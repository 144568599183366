define("ui/components/form-pod-scheduling-row/component", ["exports", "ui/components/form-pod-scheduling-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TYPE = ['affinity', 'antiAffinity'];
  const PRIORITY = ['required', 'preferred'];

  var _default = Ember.Component.extend({
    layout: _template.default,
    editing: true,
    showNamespace: 'false',
    priorityChoices: [],
    typeChoices: [],
    topologyChoices: [],
    namespaces: null,
    _namespaces: null,

    init() {
      this._super(...arguments);

      this.initTypeChoices();
      this.initPriorityChoices();
      this.initTopologyChoices();
      this.initShowNamespace();

      if (!!Ember.get(this, 'model.namespaces')) {
        Ember.set(this, '_namespaces', Ember.get(this, 'model.namespaces').toString());
      }
    },

    actions: {
      remove() {
        if (this.remove) {
          this.remove(this.model);
        }
      },

      modifyNamespaces(select) {
        let options = Array.prototype.slice.call(select.target.options, 0);
        let selectedOptions = [];
        options.filterBy('selected', true).forEach(ns => selectedOptions.push(ns.value));
        Ember.set(this, 'model.namespaces', selectedOptions);
      }

    },
    termsChanged: Ember.observer('model.labelSelector.matchExpressions.@each.{key,operater,values}', 'model.{priority,type,topologyKey,weight,namespaceSelector}', 'model.namespaces.[]', 'showNamespace', function () {
      if (this.update) {
        this.update();
      }
    }),
    namepsacesChanged: Ember.observer('_namespaces', function () {
      if (!Ember.get(this, '_namespaces')) {
        Ember.set(this, 'model.namespaces', null);
      } else {
        Ember.set(this, 'model.namespaces', Ember.get(this, '_namespaces').split(','));
      }
    }),
    weightChanged: Ember.observer('model.weight', function () {
      if (!!Ember.get(this, 'model.weight')) {
        Ember.set(this, 'model.priority', 'preferred');
      } else {
        Ember.set(this, 'model.priority', 'required');
      }
    }),
    initTopologyChoices: Ember.observer('nodes.[]', function () {
      const uniqueObj = {};
      Ember.get(this, 'nodes').forEach(node => {
        Object.keys(node.metadata.labels).forEach(l => uniqueObj[l] = true);
      });
      Ember.set(this, 'topologyChoices', Object.keys(uniqueObj).sort().map(k => {
        return {
          label: k,
          value: k
        };
      }));
    }),
    showNamepsaceChanged: Ember.observer('showNamespace', function () {
      if (Ember.get(this, 'showNamespace') === 'true') {
        Ember.set(this, 'model.namespaces', []);
        Ember.set(this, '_namespaces', null);
        Ember.set(this, 'model.namespaceSelector', null);
      } else if (Ember.get(this, 'showNamespace') === 'all') {
        Ember.set(this, 'model.namespaceSelector', {});
        Ember.set(this, 'model.namespaces', null);
        Ember.set(this, '_namespaces', null);
      } else {
        Ember.set(this, 'model.namespaceSelector', null);
        Ember.set(this, 'model.namespaces', null);
        Ember.set(this, '_namespaces', '');
      }
    }),

    initTypeChoices() {
      Ember.set(this, 'typeChoices', TYPE.map(k => {
        return {
          translationKey: `nodeDriver.harvester.scheduling.input.type.${k}`,
          value: k
        };
      }));
    },

    initPriorityChoices() {
      Ember.set(this, 'priorityChoices', PRIORITY.map(k => {
        return {
          translationKey: `nodeDriver.harvester.scheduling.input.priority.${k}`,
          value: k
        };
      }));
    },

    initShowNamespace() {
      let out = 'false';
      const namespaceSelector = this.model.namespaceSelector;

      if (namespaceSelector && typeof namespaceSelector === 'object' && !Object.keys(namespaceSelector).length) {
        out = 'all';
      } else if (this.model.namespaces) {
        out = 'true';
      }

      Ember.set(this, 'showNamespace', out);
    }

  });

  _exports.default = _default;
});