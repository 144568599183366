define("ui/authenticated/cluster/cluster-catalogs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PxNHx+6j",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],null,[[\"color\"],[\"bg-warning\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[12],[1,[30,[36,0],[\"banner.catalogs\"],[[\"htmlSafe\"],[true]]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"section\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"catalogSettings.header\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"right-buttons\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn bg-primary\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"add\"],null],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"catalogSettings.more.addActionLabel\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,4],null,[[\"catalogs\",\"mode\"],[[35,3],\"cluster\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"banner-message\",\"action\",\"filtered\",\"custom-catalog\"]}",
    "moduleName": "ui/authenticated/cluster/cluster-catalogs/template.hbs"
  });

  _exports.default = _default;
});