define("ui/components/security-header/component", ["exports", "shared/utils/util", "ui/components/security-header/template"], function (_exports, _util, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    showLegacyMessage: (0, _util.isEmbedded)()
  });

  _exports.default = _default;
});