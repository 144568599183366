define("ui/authenticated/embed/route", ["exports", "shared/utils/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect(params) {
      if (params.path.indexOf('dashboard') === 0) {
        if ((0, _util.isEmbedded)()) {
          const page = params.path.substr(9);
          (0, _util.dashboardWindow)().postMessage({
            action: 'dashboard',
            page
          });
        }

        return;
      }

      const r = `/c/${params.path}`;
      this.replaceWith(r);
    }

  });

  _exports.default = _default;
});