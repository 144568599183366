define("ui/authenticated/prefs/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    scope: Ember.inject.service(),
    globalStore: Ember.inject.service(),

    model()
    /* params, transition*/
    {
      return Ember.get(this, 'globalStore').find('user', null, {
        forceReload: true,
        filter: {
          me: true
        }
      }).then(user => Ember.Object.create({
        account: Ember.get(user, 'firstObject')
        /* dont like this */

      }));
    }

  });

  _exports.default = _default;
});